@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/poppins/Poppins-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SfUiText';
    src: url('../fonts/sfui/SF-UI-Text-Regular.woff') format('woff'),
        url('../fonts/sfui/SF-UI-Text-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SfUiText';
    src: url('../fonts/sfui/SF-UI-Text-Semibold.woff') format('woff'),
        url('../fonts/sfui/SF-UI-Text-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
